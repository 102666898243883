import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import { Col, Form, Row } from 'react-bootstrap';
import DialogActions from 'components/widgets/modal/DialogActions';
import InputNumber from 'components/widgets/InputNumber';
import DatePicker from 'react-datepicker';

const useStyles = makeStyles((theme) => ({
    row: {
        margin: 0,
        marginBottom: 20,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    dialogContent: {
        padding: theme.spacing(3, 4),
    },
    group: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 5px',
        '& > label': {
            marginBottom: 0,
            marginRight: 10,
            minWidth: 160,
            textAlign: 'end',
            color: theme.palette.text.minsk,
            fontSize: '14px',
            fontWeight: 500,
        },
        '& > *:last-child': {
            flex: 1,
        },
    },
    styleColLeft: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    alignTextEnd: {
        textAlign: 'end',
    },
    labelStyle: {
        marginBottom: '2px !important',
        fontWeight: 'bold !important',
        fontSize: '14px !important',
        color: '#767676',
    },
    switchMargin: {
        marginLeft: '10px',
    },
}));

const RollPaymentDialog = ({
    toggleModal,
    onChange,
    paymentOverride,
    paymentAmount,
    financeAmount,
    finalPaymentDue,
    finalPaymentAmount,
    resetDealInformation,
    minimumPaymentOverride,
}) => {
    const classes = useStyles();
    const maximumPaymentOverride = financeAmount / 2;

    return (
        <Dialog
            open
            fullWidth
            maxWidth="xs"
            aria-labelledby="roll-payment-dialog-title"
            aria-describedby="roll-payment-dialog-description"
        >
            <DialogAppBar title="Roll Payment" onClose={toggleModal} iconSize="sm" titleVariant="h4" />
            <DialogContent className={classes.dialogContent} dividers>
                <Form className="am-form">
                    <Form.Row className={classes.row}>
                        <Col>
                            <Form.Group>
                                <Form.Label className="blank-label" />
                                <Row noGutters className={classes.styleColLeft}>
                                    <Col className={classes.alignTextEnd} xs={8}>
                                        <Form.Label className={classes.labelStyle}>Roll Payment</Form.Label>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Check
                                            checked={paymentOverride}
                                            className={classes.switchMargin}
                                            type="switch"
                                            id="roll-payment-switch"
                                            label=""
                                            onChange={(e) => onChange('paymentOverride', e.target.checked)}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label className={classes.labelStyle}>Desired Payment</Form.Label>
                                <InputNumber
                                    disabled={!paymentOverride}
                                    value={paymentAmount}
                                    onChange={(value) => onChange('paymentAmount', value)}
                                    placeholder="0.00"
                                    thousandSeparator
                                    max={maximumPaymentOverride}
                                    showCurrency
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <Form.Group>
                                <Form.Label className={classes.labelStyle}>Final Payment Amount</Form.Label>
                                <InputNumber
                                    disabled
                                    showCurrency
                                    value={finalPaymentAmount}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="full-width-date-picker">
                                <Form.Label className={classes.labelStyle}>Final Payment Date</Form.Label>
                                <DatePicker
                                    disabled
                                    className="form-control"
                                    placeholderText="mm/dd/yyyy"
                                    selected={finalPaymentDue}
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </Form>
            </DialogContent>
            <DialogActions
                titlePrimary="Apply"
                onClickPrimary={toggleModal}
                onClickSecondary={() => { resetDealInformation(); toggleModal(); }}
                disablePrimaryButton={!(paymentAmount >= minimumPaymentOverride && paymentAmount <= maximumPaymentOverride)}
            />
        </Dialog>
    );
};

RollPaymentDialog.propTypes = {
    toggleModal: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    paymentAmount: PropTypes.number.isRequired,
    financeAmount: PropTypes.number.isRequired,
    paymentOverride: PropTypes.bool.isRequired,
    finalPaymentAmount: PropTypes.number.isRequired,
    finalPaymentDue: PropTypes.object.isRequired,
    resetDealInformation: PropTypes.func.isRequired,
    minimumPaymentOverride: PropTypes.number.isRequired,
};

export default RollPaymentDialog;
